<template>
  <v-container class="products">
    <Breadcrumb :items="breadcrumbs" />

    <CategoryTitle
      :category="category"
      :selectedCategory="selectedCategory"
      :count="count"
      height="150px"
      class="mb-6"
    />

    <div class="subcategories mb-4" v-if="!categoryId">
      <v-chip
        v-for="subCategory in category.children"
        :key="subCategory.categoryId"
        label
        link
        :to="{
          name: 'Category',
          params: {
            pathMatch: category.slug
          },
          query: { categoryId: subCategory.categoryId }
        }"
        filter
        class="mr-2 mb-2"
      >
        {{ subCategory.name }}
      </v-chip>
    </div>

    <!-- <v-row v-if="!categoryId">
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="subCategory in category.children"
        :key="subCategory.categoryId"
      >
        <v-card
          flat
          height="100%"
          :to="{
            name: 'Category',
            params: {
              pathMatch: category.slug
            },
            query: { categoryId: subCategory.categoryId }
          }"
        >
          <v-img
            contain
            :src="subCategory.metaData.category_info.THUMB"
            :alt="subCategory.name"
            class="center-img rounded-sm"
          ></v-img>
          <v-card-title class="category-block-title">{{
            subCategory.name
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row> -->
    <ProductListGrid
      :parentCategoryId="category.categoryId"
      :categoryId="categoryId"
      :key="key"
      :hidePromoFilter="true"
      hideFilterString="Categorie"
      @productsCount="updateCount"
    />
  </v-container>
</template>
<style scoped lang="scss">
.category-block-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
  justify-content: center;
  word-break: break-word;
}
.center-img {
  margin-right: auto;
  margin-left: auto;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .subcategories {
    white-space: nowrap;
    overflow: scroll;
  }
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle";
import ProductListGrid from "@/components/product/ProductListGrid";
import Breadcrumb from "@/components/navigation/Breadcrumb";

import categoryMixins from "~/mixins/category";

import toNumber from "lodash/toNumber";
import clone from "lodash/clone";
import { mapActions } from "vuex";

export default {
  name: "CategoryLeaflet",
  mixins: [categoryMixins],
  data() {
    return { categoryId: null, count: null, key: 1 };
  },
  components: {
    CategoryTitle,
    ProductListGrid,
    Breadcrumb
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = clone(this.breadcrumb);

      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    },
    ...mapActions({
      resetFilters: "category/resetFilters"
    })
  },
  created() {
    if (this.$route.query.categoryId) {
      this.categoryId = toNumber(this.$route.query.categoryId);
    }
  },
  watch: {
    async "$route.query.categoryId"(newVal) {
      if (newVal != this.categoryId) {
        this.categoryId = newVal; //this.$route.query.categoryId;
        await this.resetFilters(newVal);
        this.key++;
      }
    }
  }
};
</script>
